import RoleCard from '../RoleCard.vue';
import RoleMenu from '../RoleMenu.vue';
import {ref, reactive, toRefs, onBeforeMount, defineComponent, getCurrentInstance} from 'vue';
import {TableColumnCtx} from "element-plus/es/components/table/src/table-column/defaults";
import config from "@/utils/config";
import language from '../ts/roleLanguage'
const RoleListHelper = defineComponent({
    name: "roleList",
    props: {
        //以弹出框的形式包含该模块的时候会传入该参数(由jh.core.js的dialog传入)，在此模块中用该参数控制固定列的显示
        engineParams: {
            type: Object
        }
    },
    setup(props,context){
        let {proxy}=getCurrentInstance() as any;
        const utils=proxy.utils;
        let dataObj=reactive({
            pageListRef:null,
            pageList: {
                queryParam: {
                    from:props.engineParams?'others':''
                },
                modelComp:RoleCard,
                modelMethod: utils.Api.buildUrl("/role/pageData")
            },
            flag1:config.flag1
        })
        onBeforeMount(()=>{
            proxy.addLangProps(language);
        })
        //----------------列表数据表格的编辑、删除和角色授权
        const permissionHandler=async (row:any)=>{
            await utils.UtilPub.openDialog({proxy:proxy,addOrEdit:'edit',id:row.F_ID,modelComp:RoleMenu,ownerComp:dataObj.pageListRef,
                                                    title: proxy.$t('role.menu_authorization_management'),contentHeight:'',showFooterBtn:false,fullscreen:true})
        }
        //---------------数据表格行格式化
        const formatterDataLevel=(row:any, column:TableColumnCtx<any>, value:any, index:number)=>{
            if (0 == value) {
                return proxy.$t('role.personal');
            } else if (1 == value) {
                return proxy.$t('role.department');
            } else if (2 == value) {
                return proxy.$t('role.department_cascade');
            } else if (3 == value) {
                return proxy.$t('role.organization');
            } else if (4 == value) {
                return proxy.$t('role.organization_cascade');
            } else {
                return "";
            }
        }
        const formatterPortalLevel=(row:any, column:TableColumnCtx<any>, value:any, index:number)=>{
            if (0 == value) {
                return proxy.$t('role.columns.VIEW');
            } else if (1 == value) {
                return proxy.$t('role.columns.EDIT');
            } else {
                return "";
            }
        }
        const filterDataLevel=(value:any, row:any)=>{
            return row.F_DATA_LEVEL === value;
        }
        const filterPortalLevel=(value:any, row:any)=>{
            return row.F_PORTAL_LEVEL === value;
        }
        const gridLoaded=(res:any)=>{

        }
        const dataLevelFilters=()=>{
            let needDept=utils.Tools.getPropFromVuex('needDept');
            let needCorp=utils.Tools.getPropFromVuex('needCorp');
            let result=[{ text: proxy.$t('role.personal'), value: '0' }];
            if(needDept){
                result.push({ text: proxy.$t('role.department'), value: '1' });
                result.push({ text: proxy.$t('role.department_cascade'), value: '2' });
            }
            if(needCorp){
                result.push({ text: proxy.$t('role.organization'), value: '3' });
                result.push({ text: proxy.$t('role.organization_cascade'), value: '4' });
            }
            return result;
        }
        return{
            ...toRefs(dataObj),permissionHandler,formatterDataLevel,formatterPortalLevel,filterDataLevel,filterPortalLevel,gridLoaded,dataLevelFilters
        }
    }
});
export default RoleListHelper;