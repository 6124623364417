import BaseBean from "@/utils/BaseBean";

interface ITreeProps {
    disabled:any
    children: string
    label: string
}
interface ITopMenuTree {
    topMenus:Array<{menuId:string,title:string}>
    trees:Array<{parentId:string,treeData:any}>
}
export interface IRoleMenuDataObj {
    utilInst:RoleMenuUtil,
    treeRef: any
    compParams:any
    defaultProps:ITreeProps//设置树的显示属性和孩子属性是json数据中的哪个字段
    topMenuTree: any //顶层tab和tab包含的树节点信息
    activeMenuId: string //当前点击的活动menu节点
    treeData: Array<any> //当前被选择的tab要显示的树
    disabled:boolean
    menuSelPermissionItems:any //每个menu节点的功能权限，里面是一个个的menuId作为key，一个初始化的空数组作为value，动态添加的属性
    lastStageSelMenuIds:Array<string> //选中打勾的菜单项
    roleId: string
    engineInst:any
    form: any
}
export default class RoleMenuUtil extends BaseBean{
    public dataObj:IRoleMenuDataObj
    constructor(proxy:any,dataObj:IRoleMenuDataObj) {
        super(proxy);
        this.dataObj=dataObj;
    }

    //初始化角色菜单卡片，包括卡片头部按钮、菜单信息，以及该角色配置的菜单信息
    public async reloadRoleMenuInfo(engineInst:any):Promise<void>{
        let res = await this.utils.Api.loadRoleMenu({ roleId: engineInst.engineParams.id,modelType:'card'});
        engineInst.buttons=res.buttons;

        this.buildTopMenuTree(JSON.parse(res.roleMenuInfos.userMenus));//构建菜单tab和tab里面的顶级树节点
        this.dataObj.menuSelPermissionItems = res.roleMenuInfos.menuSelPermissionItems;
        this.dataObj.lastStageSelMenuIds = res.roleMenuInfos.lastStageSelMenuIds;
        this.dataObj.treeRef.setCheckedKeys(res.roleMenuInfos.lastStageSelMenuIds);//根据该用户所拥有的末级菜单选择当前正在显示的树中对应树节点

        this.dataObj.roleId = engineInst.engineParams.id;//dialogInst实例已经传给当前组件了，可以通过dialogInst实例得到从列表传入给它的id，这个id就是角色id
        this.dataObj.engineInst=engineInst;//保存dialogInst实例到当前组件，方便使用
        this.dataObj.disabled=res.disabled;
    }
    //构建顶级菜单
    public buildTopMenuTree(userMenus:any):void{
        let topMenuTree:{
            topMenus:Array<{menuId:string,title:string}>,
            trees:Array<{parentId:string,treeData:any}>
        } = {
            topMenus: [], //头部显示的顶层菜单集合，相当于tab，结构是：[{顶层菜单ID,顶层菜单ID的标题},{},{}...]
            trees: [] //每点击一个顶部菜单，下方要显示的树结构，先把所有的树结构放到一个数组里面，结构是：[{顶层菜单ID，这个顶层菜单的所有子菜单（包括顶层菜单）},{},{}...]
        };
        //根据所有菜单构建上面的topMenuTree结构
        for (let i = 0; i < userMenus.length; i++) {//注意：只会循环顶层菜单个数，因为没有去递归子菜单
            let menuItem = userMenus[i];
            menuItem.menuItemPermission =userMenus[i].menuItemPermission;
            topMenuTree.topMenus.push({menuId:menuItem.id,title:menuItem.title});//由于只循环了顶层菜单，没有递归子菜单，所以topMenuTree下的topMenus数组只存放了顶层菜单信息
            topMenuTree.trees.push({parentId:menuItem.id,treeData:menuItem});//把构造好的树节点放进topMenuTree下trees数组中，有多少个顶层菜单就会放进去多少个树节点
        }
        this.dataObj.topMenuTree = topMenuTree;
        //初始化的时候，默认第一个菜单是激活项
        this.dataObj.activeMenuId = this.dataObj.topMenuTree.topMenus[0].menuId;
        this.dataObj.treeData = [this.dataObj.topMenuTree.trees[0].treeData];
        this.dataObj.treeRef.props.disabled=()=>{return this.dataObj.disabled};
    }
    //点击顶部tab菜单，在下方显示该顶部菜单的菜单内容树
    public switchMenu(menuId:string):void{
        //如果当前点击的menuId就是正在显示的menu，那么直接返回
        if (menuId == this.dataObj.activeMenuId) return;
        //1.处理当前要离开的tree，看哪些节点选中了，哪些又被取消选中了
        let treeNode = this.dataObj.treeRef.data;//当前树的所有节点
        let checkedKeys = this.dataObj.treeRef.getCheckedKeys();//当前树已经选中的节点
        this.addSelKeys();
        //2.把本次取消选中但是还在选中列表中的key去除掉
        this.removeUnSelKeys(treeNode, checkedKeys);

        this.dataObj.activeMenuId = menuId;//更改了tab项，则需要改变tab的激活项id，用于高亮显示
        let trees = this.dataObj.topMenuTree.trees;
        for (let i = 0; i < trees.length; i++) {//寻找当前激活项应该显示的树节点的数据源
            if (menuId == trees[i].parentId) {
                this.dataObj.treeData = [trees[i].treeData];
                break;
            }
        }
        //3.当把新的tree显示出来之后，需要把已经选中的节点给选中出来
        this.dataObj.treeRef.setCheckedKeys(this.dataObj.lastStageSelMenuIds);
        this.dataObj.treeRef.props.disabled=()=>{return this.dataObj.disabled};
    }
    //往lastStageSelMenuIds添加本次选中的树节点，添加之前要看是否lastStageSelMenuIds中已经存在了
    public addSelKeys():void{
        //处理当前要离开的tree，看哪些节点选中了，哪些又被取消选中了
        let checkedKeys = this.dataObj.treeRef.getCheckedKeys();
        //1.本次选中的节点是否已经添加进入已选中节点数组中
        for (let i = 0; i < checkedKeys.length; i++) {
            if (this.dataObj.lastStageSelMenuIds.indexOf(checkedKeys[i]) == -1) {
                this.dataObj.lastStageSelMenuIds.push(checkedKeys[i]);
            }
        }
    }
    //从lastStageSelMenuIds数组中移除掉本次取消选择的树节点
    //nodes为当前树的所有节点，checkedKeys为当前树选中的节点
    public removeUnSelKeys(nodes:any, checkedKeys:any):void{
        for (let i = 0; i < nodes.length; i++) {//循环当前树所有节点
            let node = nodes[i];
            //如果当前节点没有被选中，但是该节点之前被添加进lastStageSelMenuIds数组中了，那么就移除它
            if (checkedKeys.indexOf(node.id) == -1 && this.dataObj.lastStageSelMenuIds.indexOf(node.id) > -1) {
                this.dataObj.lastStageSelMenuIds.splice(this.dataObj.lastStageSelMenuIds.indexOf(node.id), 1);
            }
            //如果该节点还有子节点，则递归所有的子节点
            if (node.children) {
                this.removeUnSelKeys(node.children, checkedKeys);
            }
        }
    }
    //得到本次角色选择的菜单和菜单的角色
    public getSelMenuAndPermissionData():any{
        let treeNode = this.dataObj.treeRef.data;
        let checkedKeys = this.dataObj.treeRef.getCheckedKeys();
        //保存之前，需要把当前已经选择和去除的菜单项在lastStageSelMenuIds数组中处理一下
        this.dataObj.utilInst.addSelKeys();
        this.dataObj.utilInst.removeUnSelKeys(treeNode, checkedKeys);
        //返回要保存的数据信息
        return {
            lastStageSelMenuIds: this.dataObj.lastStageSelMenuIds,
            menuSelPermissionItems: this.dataObj.menuSelPermissionItems
        };
    }
    //给当前树节点每个叶子节点赋值上全部权限
    public selAllPermission(data:any):void{
        const id=data.id;
        if(data.children){//有孩子节点
            for(let i=0;i<data.children.length;i++){
                this.selAllPermission(data.children[i]);
            }
        }else{//叶子节点
            const menuItemPermission=data.menuItemPermission;
            let permissionValue=[] as any;
            menuItemPermission.forEach((item:any,index:number)=>{
                permissionValue.push(item.code);
            },permissionValue);
            this.dataObj.menuSelPermissionItems[id]=permissionValue;
            this.dataObj.treeRef.setChecked(id,true);//把该节点打上勾
        }
    }
    //给当前树节点每个叶子节点的权限全部清空
    public cancelAllPermission(data:any):void{
        const id=data.id;
        if(data.children){//有孩子节点
            for(let i=0;i<data.children.length;i++){
                this.cancelAllPermission(data.children[i]);
            }
        }else{//叶子节点
            this.dataObj.menuSelPermissionItems[id]='';
            this.dataObj.treeRef.setChecked(id,false);//把该节点勾取消掉
        }
    }
    //得到本次角色需要保存的菜单和菜单的权限（该方法是对上面方法结果数据的处理，筛选出要保存的数据）
    public getSaveData(menuSelPermissionItems:any,lastStageSelMenuIds:any):any{
        //为了少传一些数据给后台，只给后台传输勾选了的菜单。如果这个勾选了这个菜单，但是木有勾选任何权限点，这个交给后台处理。
        //后台会看这个菜单本身是否有权限点让用户勾选，如果有，但是用户没有勾选则放弃这个菜单；如果这个菜单本来就没有提供权限点给用户勾选，
        //比如服务器监控、资源文件等，这种没有提供权限点的菜单，你让他勾选什么，所以针对这种菜单你只要勾选了菜单，不管勾选权限点没有，都不能放弃它
        for (let key in menuSelPermissionItems) {
            if (lastStageSelMenuIds.indexOf(key) == -1) {
                delete menuSelPermissionItems[key];
            }
        }
        //上面已经处理了menuSelPermissionItems，下面为什么还要构造整理一下menuSelPermissionItems呢
        //因为menuSelPermissionItems的权限点是一个数组，如果直接把menuSelPermissionItems传入后台，
        //那么就需要在后台把权限点处理一下，这里直接在前台处理，没有交给后台，
        //把menuSelPermissionItems中的权限点由数组全部转为字符串加逗号连接起来
        let menuPermissions = {} as any;
        for (let key in menuSelPermissionItems) {
            menuPermissions[key] = menuSelPermissionItems[key].join(',');
        }
        return menuPermissions;
    }
}