import {ref, reactive, toRefs, onMounted, defineComponent, getCurrentInstance, computed} from 'vue';

export default defineComponent({
    name: "roleCard",
    title: "角色管理",
    modelType:'card',
    fullscreen: false,
    setup(){
        const {proxy}=getCurrentInstance() as any;
        const utils=proxy.utils;
        let dataObj=reactive({
            formRef:null,
            compParams: {
                modelPath: utils.Api.buildUrl("/role")
            },
            form: {} as any,
            disabled: false,
            flag: true,
            rules: utils.UtilPub.commonFormValidRule([{code:proxy.$t('role.form.code_rule')},{name:proxy.$t('role.form.name_rule')}])
        })
        onMounted(async ()=>{

        })
        const beforeOpen=async(data:any,addOrLoad:string,cardEngine:any)=>{
            dataObj.disabled=data.disabled;
        }
        return{
            ...toRefs(dataObj),beforeOpen
        }
    },
    components: {}
});